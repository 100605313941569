import { ResponseSeoInfo } from '@/src/interfaces/data-contracts';
import type { Metadata } from 'next';

export const StandartMetaData = {
  baseName: 'HangiKredi Kobi',
  baseTitle: 'HangiKredi Kobi',
  baseDescription:
    'KOBİ kredileri nasıl alınır? KOBİ kredisi nedir? Esnaf kredisi hesaplaması ve başvuru için gerekli şartlar, faiz oranları, tüm esnaf kredisi veren bankalar',
  baseUrl: 'https://www.hangikredikobi.com/',
  baseImageUrl: 'https://statics-hangikredi.mncdn.com/images/hk-new-logo.svg',
  store: {
    appName: 'HangiKredi Kobi - Finansal Asistan',
    appStore: 'https://apps.apple.com/us/app/hangikredi/id1610287797',
    appStoreId: '1610287797',
    playStore: 'https://play.google.com/store/apps/details?id=com.hangikredi',
    playStoreId: 'com.hangikredi',
  },
  creator: 'HangiKredi',
  verification: {
    google: 'google',
    yandex: 'yandex',
    yahoo: 'yahoo',
    other: {
      me: ['my-email', 'my-link'],
      'facebook-domain-verification': '6tcrd0a9ufyza1fcl9uvuw4xxrsjjw',
    },
  },
};

export const generateMetaDataForPageWithSeoInfo = (seoInfo: ResponseSeoInfo): Metadata => {
  let { title, metaDescription, canonicalUrl } = seoInfo;

  if (canonicalUrl?.includes('hangikredi.com')) {
    canonicalUrl = canonicalUrl.replace('hangikredi.com', 'hangikredikobi.com');
  }

  if (seoInfo) {
    return {
      title: title,
      description: metaDescription,
      alternates: {
        canonical: canonicalUrl,
      },
      openGraph: {
        title: title ?? '',
        description: metaDescription ?? '',
        type: 'website',
        url: canonicalUrl ?? '',
        siteName: StandartMetaData.baseName,
        images: 'https://statics-hangikredi.mncdn.com/images/hk-thumb-logo.png',
      },
      twitter: {
        title: title ?? '',
        card: 'summary',
        description: metaDescription ?? '',
        site: StandartMetaData.baseUrl,
        creator: StandartMetaData.baseUrl,
        images: 'https://statics-hangikredi.mncdn.com/images/hk-thumb-logo.png',
      },
    };
  }
  return {};
};

const PageSeoInfoDatas = {
  'page/ticari-kredi': {
    title: 'Kobi Çatı Ticari Krediler Anasayfa',
    metaDescription: 'Kobi Çatı Ticari Krediler Anasayfa',
    canonicalUrl: 'https://www.hangikredikobi.com/',
    headingTitle: 'KOBİ Kredisi',
    heroDescription:
      'En avantajlı esnaf kredileri Hangikredi’de! KOBİ destek kredisi kullanmak için ücretsiz hesaplama ve başvuru yapabilirsiniz.',
    friendlyUrl: null,
    entity: null,
    entityId: null,
    statusParameters: 0,
    image: 'https://statics-hangikredi.mncdn.com/images/hklogo.png',
    badge: null,
  },
} as unknown as {
  [K: string]: ResponseSeoInfo;
};

export const generateMetaDataForPageWithDummySeoInfo = (pageRoute?: string) => {
  if (pageRoute) {
    return PageSeoInfoDatas[pageRoute];
  }
  return {};
};
