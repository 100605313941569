'use client';

import { prepare_virtualPageUrl } from '@/lib/data-layer/utils';
import DataLayerPageComponent from '@/src/features/shared/data-layer-component';
import Error404 from '@/src/features/shared/error-404';
import { generateMetaDataForPageWithSeoInfo } from '@/src/utils/helpers/meta-data';
import { Metadata } from 'next';
import { useEffect } from 'react';

const mockPageData = {
  page: {
    id: 'NULL',
    code: 'global-error',
    name: 'Error | Global Error',
    category: null,
    midCategory: null,
    subCategory: null,
    type: null,
    sharingType: 'WebSite',
    heroButtonText: null,
    whatIsTitle: null,
    desktopImagePath1: null,
    mobileImagePath1: null,
    desktopImagePath2: null,
    mobileImagePath2: null,
  },
  seoInfo: {
    title: 'HangiKredi Kobi Error | Global Error',
    metaDescription: 'Error | Global Error',
    canonicalUrl: 'https://www.hangikredikobi.com/global-error',
    headingTitle: 'Error | Global Error',
    heroDescription: 'Error | Global Error',
    friendlyUrl: null,
    entity: null,
    entityId: null,
    statusParameters: 0 as any,
    image: 'https://statics-hangikredi.mncdn.com/images/hklogo.png',
    badge: null,
  },
};
declare global {
  interface Window {
    newrelic: any;
  }
}
export default function GlobalError({ error }: Readonly<{ error: Error & { digest?: string } }>) {
  useEffect(() => {
    console.error('Global Error', error);
    if (typeof window == 'undefined') {
      const newrelic = require('newrelic');
      newrelic.noticeError(error.message);
    } else {
      window.newrelic.noticeError(error.message);
    }
  }, [error]);

  const prepareVirtualPageUrl = prepare_virtualPageUrl({
    pathname: `/global-error`,
    searchParams: error.message,
  });
  const prepareDataLayerData = {
    page: { ...mockPageData.page, virtualPageUrl: prepareVirtualPageUrl },
    otherData: mockPageData.seoInfo,
  };

  return (
    <html lang="tr">
      <body>
        <DataLayerPageComponent type="page" argms={prepareDataLayerData} />
        <Error404 status="global-error" />
      </body>
    </html>
  );
}

export async function generateMetadata(): Promise<Metadata> {
  return generateMetaDataForPageWithSeoInfo(mockPageData.seoInfo ?? {});
}
